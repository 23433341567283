import React from 'react';
import  Helmet  from "react-helmet";
const AboutUs = () => {
  return (
  

 <div className='py-6'>

<Helmet>
 
<title>Codeexampler About US</title>
<meta name="description" content="Codeexampler About US.CodeExampler provides Multiple Programming Examples for Beginners,CodeExampler provides Free Online Tutorials for beginners and  questions of all technology like java tutorial, python tutorial,c# tutorial for beginners in deeply knowledge " />
<link rel="canonical" href="https://www.codeexampler.com/about-us" />
</Helmet>

 <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
   <h1 className="text-3xl font-bold ">
     Welcome to CodeExampler
   </h1>
   <p className="mt-2 text-lg ">
   your go-to source for free programming tutorials for beginners. We provide high-quality tutorials in popular programming languages like Python, Java, C#, C++, PHP, C, and more. Our goal is to make programming accessible and easy to understand for anyone who wants to learn.
   </p>
   <p className="mt-2 text-lg ">
   At CodeExampler, we believe that everyone has the potential to learn and excel in programming. That's why we offer tutorials that cater to different learning styles and skill levels. Whether you're a complete beginner or an experienced programmer looking to learn a new language, we've got you covered.
   </p>
   <p className="mt-2 text-lg ">
   Our team of expert programmers and educators work tirelessly to create comprehensive and engaging tutorials that make learning fun and enjoyable. We cover a wide range of topics, from the basics of programming concepts to advanced techniques and real-world applications.
   </p>
   <div className="mt-8">
   
 
     <p className="mt-2 text-lg ">
     Our tutorials are free and accessible to anyone, anywhere, at any time. We want to help you develop your skills and achieve your programming goals, no matter what they are. We also offer a range of resources, including coding challenges and exercises, to help you practice and reinforce what you've learned.
     </p>
     <p className="mt-2 text-lg ">
     At CodeExampler, we're committed to creating a supportive and inclusive learning community. We believe that everyone deserves a chance to learn and grow, regardless of their background or circumstances. That's why we strive to make our tutorials and resources accessible to everyone.
     </p>
   </div>
   <div className="mt-8">
     <p className="text-lg font-bold">
       Our Mission Statement
     </p>
     <p className="mt-2 text-lg ">
     We're passionate about programming and love sharing our knowledge with others. We hope that you find our tutorials helpful and inspiring, and that you join our community of learners. If you have any questions, suggestions, or feedback, please don't hesitate to reach out to us.
     </p>
     <p className="mt-2 text-lg ">
       Our mission is to provide the best quality business listings and
       help customers find the right service providers in their area. We
       strive to create a platform that is easy to use, informative, and
       effective in helping you find the business you're looking for.
     </p>
     <p className="mt-2 text-lg ">
     Thank you for choosing CodeExampler, and happy learning!
</p>
   </div>
   
   <div className="mt-8">
     <p className="text-lg font-medium ">
 Check PgWars Free Paying Guest Listing Directory
     </p>
     <p className="mt-2 text-lg ">
  If you Want To Search <a class="text-blue-600" target="_blank" href="https://www.pgwars.com/">PGWars Free Pg Listing Directory</a> Search all type of Pg with Best Room Price 
</p>
<br/>
<p className="text-lg font-medium">
 Check ResumerPro Free Resume Builder Website
     </p>
     <p className="mt-2 text-lg ">
  If you Want To <a class="text-blue-600" target="_blank" href="https://www.resumerpro.com/">ResumerPro is an excellent free resume builder Site that offers users the ability to create professional and creative resumes</a> so Create and free Download.
</p> 

   </div>
   
   
    </div> </div>
  );
};
export default AboutUs;
