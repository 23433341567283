export const IconEqual = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9H18" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path d="M8 17H18" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path d="M1 1H11" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>
    )
}

export const IconCE = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 47 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.3027 27.8928L17.818 30.1663C14.9202 30.9427 12.609 30.9408 10.8843 30.1606C9.1828 29.3742 8.00903 27.7756 7.36302 25.3646L4.46528 14.5501C3.81927 12.1391 4.03644 10.1678 5.11679 8.63595C6.21411 7.07475 8.21166 5.90592 11.1094 5.12946L19.5941 2.85599C20.0346 2.73797 20.3755 2.75843 20.6169 2.91738C20.8752 3.04693 21.0634 3.33194 21.1814 3.7724L22.3088 7.97998C22.4268 8.42044 22.4095 8.77294 22.2567 9.03749C22.121 9.27264 21.8329 9.44922 21.3924 9.56725L14.403 11.4401C13.6843 11.6326 13.1915 11.9262 12.9245 12.3207C12.6513 12.6921 12.5924 13.1675 12.7477 13.7471L14.9652 22.0231C15.1205 22.6027 15.4093 22.985 15.8316 23.17C16.2476 23.3318 16.815 23.3165 17.5336 23.1239L24.5231 21.2511C24.9635 21.1331 25.3045 21.1535 25.5458 21.3125C25.8042 21.442 25.9923 21.727 26.1103 22.1675L27.2191 26.3055C27.3372 26.746 27.3198 27.0985 27.1671 27.363C27.0313 27.5982 26.7432 27.7748 26.3027 27.8928Z" fill="white" />
            <path d="M42.9745 29.4653H26.2705C25.8145 29.4653 25.4785 29.3693 25.2625 29.1773C25.0705 28.9613 24.9745 28.6253 24.9745 28.1693V4.8413C24.9745 4.3853 25.0705 4.0613 25.2625 3.8693C25.4785 3.6533 25.8145 3.5453 26.2705 3.5453H42.9745C43.4305 3.5453 43.7545 3.6533 43.9465 3.8693C44.1625 4.0613 44.2705 4.3853 44.2705 4.8413V9.0533C44.2705 9.5093 44.1625 9.8453 43.9465 10.0613C43.7545 10.2533 43.4305 10.3493 42.9745 10.3493H33.0385V13.1213H40.0585C40.5145 13.1213 40.8385 13.2293 41.0305 13.4453C41.2465 13.6373 41.3545 13.9613 41.3545 14.4173V18.2693C41.3545 18.7253 41.2465 19.0613 41.0305 19.2773C40.8385 19.4693 40.5145 19.5653 40.0585 19.5653H33.0385V22.6613H42.9745C43.4305 22.6613 43.7545 22.7693 43.9465 22.9853C44.1625 23.1773 44.2705 23.5013 44.2705 23.9573V28.1693C44.2705 28.6253 44.1625 28.9613 43.9465 29.1773C43.7545 29.3693 43.4305 29.4653 42.9745 29.4653Z" fill="#01B6FF" />
        </svg>

    )
}
export const PythonIcon = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3902 5.27357C10.3902 2.56022 11.1344 1.08414 15.2433 0.38125C18.0329 -0.0967686 21.6096 -0.156521 24.946 0.38125C27.5812 0.806488 29.7991 2.72553 29.7991 5.27357V14.2255C29.7991 16.851 27.6458 19.0017 24.946 19.0017H15.2433C11.9503 19.0017 9.17493 21.7467 9.17493 24.85V29.145H5.83876C3.01677 29.145 1.37374 27.1557 0.683523 24.372C-0.247495 20.6323 -0.207973 18.4042 0.683523 14.8226C1.45645 11.6981 3.9262 10.0496 6.74839 10.0496H20.0964V8.85453H10.39V5.27338L10.3902 5.27357Z" fill="url(#paint0_linear_91_489)" />
            <path d="M29.7996 33.9215C29.7996 36.6348 27.391 38.0091 24.9465 38.6945C21.2689 39.7278 18.3173 39.5697 15.2437 38.6945C12.6768 37.9635 10.3906 36.4697 10.3906 33.9215V24.9696C10.3906 22.3933 12.5872 20.1934 15.2437 20.1934H24.9465C28.1784 20.1934 31.0148 17.4627 31.0148 14.2255V10.05H34.6529C37.4786 10.05 38.8089 12.1025 39.506 14.823C40.4766 18.6014 40.5198 21.4271 39.506 24.3725C38.5247 27.2334 37.4749 29.1455 34.6529 29.1455H20.0969V30.3405H29.7996V33.9217V33.9215Z" fill="url(#paint1_linear_91_489)" />
            <path d="M12.8173 4.67953C12.8173 3.68844 13.6297 2.88696 14.6365 2.88696C15.6394 2.88696 16.4558 3.68824 16.4558 4.67953C16.4558 5.66744 15.6397 6.46852 14.6365 6.46852C13.6301 6.46852 12.8173 5.66724 12.8173 4.67953ZM23.7348 34.5155C23.7348 33.5276 24.5509 32.7265 25.5541 32.7265C26.5605 32.7265 27.3733 33.5278 27.3733 34.5155C27.3733 35.5066 26.5609 36.308 25.5541 36.308C24.5511 36.308 23.7348 35.5068 23.7348 34.5155Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_91_489" x1="29.7993" y1="11.3301" x2="0.000319153" y2="11.0877" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#366A96" />
                    <stop offset="1" stopColor="#3679B0" />
                </linearGradient>
                <linearGradient id="paint1_linear_91_489" x1="40.25" y1="24.7308" x2="-18.7378" y2="24.7308" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFC836" />
                    <stop offset="1" stopColor="#FFE873" />
                </linearGradient>
            </defs>
        </svg>


    )
}

export const InconFb = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3049 0H2.69506C1.20665 0 0 1.20659 0 2.69504V17.3049C0 18.7933 1.2066 19.9999 2.69506 19.9999H9.90056L9.91284 12.853H8.05607C7.81477 12.853 7.61892 12.6579 7.61799 12.4166L7.60909 10.1129C7.60816 9.87025 7.80459 9.67308 8.04721 9.67308H9.90061V7.44709C9.90061 4.86384 11.4783 3.45724 13.7827 3.45724H15.6737C15.9157 3.45724 16.1118 3.65339 16.1118 3.89537V5.83789C16.1118 6.07978 15.9158 6.27587 15.674 6.27602L14.5135 6.27655C13.2603 6.27655 13.0176 6.87207 13.0176 7.74602V9.67313H15.7714C16.0338 9.67313 16.2373 9.90225 16.2064 10.1628L15.9334 12.4666C15.9072 12.687 15.7203 12.8531 15.4983 12.8531H13.0299L13.0176 20H17.305C18.7934 20 20 18.7934 20 17.305V2.69504C20 1.20659 18.7934 0 17.3049 0Z" fill="#01B6FF" />
        </svg>

    )
}

export const InconInstragram = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0419 0H5.95811C2.67279 0 0 2.67279 0 5.95811V14.0419C0 17.3272 2.67279 20 5.95811 20H14.0419C17.3272 20 20 17.3272 20 14.0419V5.95811C20 2.67279 17.3272 0 14.0419 0ZM17.988 14.0419C17.988 16.2213 16.2213 17.988 14.0419 17.988H5.95811C3.77875 17.988 2.012 16.2213 2.012 14.0419V5.95811C2.012 3.77871 3.77875 2.012 5.95811 2.012H14.0419C16.2213 2.012 17.988 3.77871 17.988 5.95811V14.0419Z" fill="#01B6FF" />
            <path d="M10 4.82727C7.14777 4.82727 4.8273 7.14774 4.8273 9.99995C4.8273 12.8521 7.14777 15.1727 10 15.1727C12.8523 15.1727 15.1727 12.8522 15.1727 9.99995C15.1727 7.14771 12.8523 4.82727 10 4.82727ZM10 13.1607C8.25439 13.1607 6.8393 11.7456 6.8393 9.99998C6.8393 8.25435 8.25442 6.83927 10 6.83927C11.7456 6.83927 13.1607 8.25435 13.1607 9.99998C13.1607 11.7456 11.7456 13.1607 10 13.1607Z" fill="#01B6FF" />
            <path d="M15.1827 6.10562C15.8673 6.10562 16.4222 5.55069 16.4222 4.86614C16.4222 4.18159 15.8673 3.62665 15.1827 3.62665C14.4982 3.62665 13.9432 4.18159 13.9432 4.86614C13.9432 5.55069 14.4982 6.10562 15.1827 6.10562Z" fill="#01B6FF" />
        </svg>

    )
}

export const IncoYoutube = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5634 2.19563C19.4484 1.77144 19.2243 1.38474 18.9135 1.07395C18.6028 0.763169 18.2161 0.539118 17.7919 0.424063C16.2387 1.11759e-07 9.9875 0 9.9875 0C9.9875 0 3.73594 0.0125001 2.1825 0.436563C1.75831 0.551618 1.37161 0.775669 1.06083 1.08645C0.750044 1.39724 0.525993 1.78394 0.410938 2.20813C0 3.76156 0 7.00938 0 7.00938C0 7.00938 0 10.2572 0.42375 11.8231C0.538806 12.2473 0.762857 12.634 1.07364 12.9448C1.38443 13.2556 1.77112 13.4796 2.19531 13.5947C3.74844 14.0188 10 14.0188 10 14.0188C10 14.0188 16.2516 14.0188 17.805 13.595C18.2292 13.4799 18.6159 13.2559 18.9267 12.9451C19.2375 12.6343 19.4615 12.2476 19.5766 11.8234C20 10.27 20 7.00938 20 7.00938C20 7.00938 19.9875 3.76156 19.5634 2.19563Z" fill="#01B6FF" />
            <path d="M7.9975 10.0131L13.1834 7.00937L7.99719 4.00562L7.9975 10.0131Z" fill="black" />
        </svg>

    )
}

export const IncoTwitter = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5 2.13081C21.6722 2.49341 20.7808 2.73514 19.8484 2.84705C20.8035 2.28301 21.5312 1.39219 21.8769 0.335737C20.9854 0.850535 19.9985 1.23104 18.9478 1.43248C18.1064 0.550609 16.9057 0 15.5776 0C13.0261 0 10.9612 2.03233 10.9612 4.54365C10.9612 4.90177 11.0021 5.24646 11.0794 5.57772C7.2453 5.3897 3.84324 3.5812 1.56458 0.832629C1.16889 1.5041 0.94148 2.28301 0.94148 3.11564C0.94148 4.69137 1.75561 6.08356 2.99272 6.89828C2.23772 6.8759 1.52365 6.66998 0.900546 6.32977C0.900546 6.34767 0.900546 6.36558 0.900546 6.38796C0.900546 8.5904 2.49242 10.4258 4.60279 10.8421C4.21619 10.945 3.80685 11.0032 3.38842 11.0032C3.09278 11.0032 2.8017 10.9764 2.51971 10.9227C3.10643 12.7267 4.81201 14.0428 6.83141 14.0786C5.25318 15.2962 3.26107 16.0214 1.10067 16.0214C0.727714 16.0214 0.359309 15.999 0 15.9587C2.04215 17.2479 4.47089 18 7.07702 18C15.5685 18 20.2122 11.0793 20.2122 5.07187C20.2122 4.87491 20.2077 4.67794 20.1986 4.48545C21.0992 3.84084 21.8814 3.03954 22.5 2.13081Z" fill="#01B6FF" />
        </svg>

    )
}

export const IncoLinkdin = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.1908 0H1.80916C0.81 0 0 0.81 0 1.80916V18.1908C0 19.19 0.81 20 1.80916 20H18.1908C19.19 20 20 19.19 20 18.1908V1.80916C20 0.81 19.19 0 18.1908 0ZM6.18885 17.2693C6.18885 17.5601 5.95314 17.7958 5.66236 17.7958H3.4212C3.13042 17.7958 2.89471 17.5601 2.89471 17.2693V7.8745C2.89471 7.58372 3.13042 7.34801 3.4212 7.34801H5.66236C5.95314 7.34801 6.18885 7.58372 6.18885 7.8745V17.2693ZM4.54178 6.46241C3.36592 6.46241 2.41267 5.50916 2.41267 4.3333C2.41267 3.15743 3.36592 2.20419 4.54178 2.20419C5.71764 2.20419 6.67089 3.15743 6.67089 4.3333C6.67089 5.50916 5.7177 6.46241 4.54178 6.46241ZM17.901 17.3117C17.901 17.5791 17.6843 17.7958 17.417 17.7958H15.012C14.7447 17.7958 14.528 17.5791 14.528 17.3117V12.905C14.528 12.2476 14.7208 10.0243 12.81 10.0243C11.3279 10.0243 11.0272 11.5461 10.9669 12.2291V17.3117C10.9669 17.5791 10.7502 17.7958 10.4828 17.7958H8.15681C7.88948 17.7958 7.67272 17.5791 7.67272 17.3117V7.83209C7.67272 7.56476 7.88948 7.34801 8.15681 7.34801H10.4828C10.7501 7.34801 10.9669 7.56476 10.9669 7.83209V8.65173C11.5164 7.82696 12.3332 7.19037 14.0722 7.19037C17.9231 7.19037 17.901 10.7881 17.901 12.7648V17.3117Z" fill="#01B6FF" />
        </svg>

    )
}

export const IconFooterC = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.3 6.7875C6.33125 6.58125 6.4 6.4 6.4875 6.24375C6.575 6.0875 6.7 5.95625 6.85625 5.85625C7.00625 5.7625 7.19375 5.71875 7.425 5.7125C7.56875 5.71875 7.7 5.74375 7.81875 5.79375C7.94375 5.85 8.05625 5.925 8.14375 6.01875C8.23125 6.1125 8.3 6.225 8.35625 6.35C8.4125 6.475 8.4375 6.6125 8.44375 6.75H9.5625C9.55 6.45625 9.49375 6.1875 9.3875 5.94375C9.28125 5.7 9.1375 5.4875 8.95 5.3125C8.7625 5.1375 8.5375 5 8.275 4.9C8.0125 4.8 7.725 4.75625 7.40625 4.75625C7 4.75625 6.64375 4.825 6.34375 4.96875C6.04375 5.1125 5.79375 5.3 5.59375 5.54375C5.39375 5.7875 5.24375 6.06875 5.15 6.39375C5.05625 6.71875 5 7.05625 5 7.41875V7.5875C5 7.95 5.05 8.2875 5.14375 8.6125C5.2375 8.9375 5.3875 9.21875 5.5875 9.45625C5.7875 9.69375 6.0375 9.8875 6.3375 10.025C6.6375 10.1625 6.99375 10.2375 7.4 10.2375C7.69375 10.2375 7.96875 10.1875 8.225 10.0938C8.48125 10 8.70625 9.86875 8.9 9.7C9.09375 9.53125 9.25 9.3375 9.3625 9.1125C9.475 8.8875 9.54375 8.65 9.55 8.39375H8.43125C8.425 8.525 8.39375 8.64375 8.3375 8.75625C8.28125 8.86875 8.20625 8.9625 8.1125 9.04375C8.01875 9.125 7.9125 9.1875 7.7875 9.23125C7.66875 9.275 7.54375 9.2875 7.4125 9.29375C7.1875 9.2875 7 9.24375 6.85625 9.15C6.7 9.05 6.575 8.91875 6.4875 8.7625C6.4 8.60625 6.33125 8.41875 6.3 8.2125C6.26875 8.00625 6.25 7.79375 6.25 7.5875V7.41875C6.25 7.2 6.26875 6.99375 6.3 6.7875ZM7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.05 13.75 7.5 13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25ZM7.5 12.5C4.74375 12.5 2.5 10.2563 2.5 7.5C2.5 4.74375 4.74375 2.5 7.5 2.5C10.2563 2.5 12.5 4.74375 12.5 7.5C12.5 10.2563 10.2563 12.5 7.5 12.5Z" fill="#01B6FF" />
        </svg>

    )
}