import React from 'react';
import  Helmet  from "react-helmet";
function Disclaimer() {
  return (

      <div className=" p-4 rounded-lg shadow-md">


<Helmet>
 
<title>Codeexampler Disclaimer</title>
<meta name="description" content="Codeexampler Disclaimer.CodeExampler provides Multiple Programming Examples for Beginners,CodeExampler provides Free Online Tutorials for beginners and  questions of all technology like java tutorial, python tutorial,c# tutorial for beginners in deeply knowledge " />
<link rel="canonical" href="https://www.codeexampler.com/disclaimer" />
</Helmet>

<h1 className="text-3xl py-4 font-bold ">
Codeexampler Disclaimer
   </h1>

        <p className="text-base  mb-4">
          The information contained on CodeExampler Free Business Listing Directory (the "Directory") is for general information purposes only. While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the Directory or the information, products, services, or related graphics contained on the Directory for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
        </p>
        <p className="text-base  mb-4">
          In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of the Directory.
        </p>
        <p className="text-base  mb-4">
          Through the Directory, you are able to link to other websites that are not under the control of CodeExampler Free Business Listing Directory. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
        </p>
        <p className="text-base  mb-4">
          Every effort is made to keep the Directory up and running smoothly. However, CodeExampler Free Business Listing Directory takes no responsibility for, and will not be liable for, the Directory being temporarily unavailable due to technical issues beyond our control.
        </p>
        <p className="text-base ">
          The views and opinions expressed by users of the Directory do not necessarily reflect the opinions or views of CodeExampler Free Business Listing Directory, its employees, or affiliates. We reserve the right to remove any listing or content that we deem inappropriate or in violation of our policies.
        </p>
        <p className="text-base  mt-4">
          By using CodeExampler Free Business Listing Directory, you agree to the above disclaimer and our terms and conditions. If you do not agree to these terms, please do not use the Directory.
        </p>
      </div>
    );
}



export default Disclaimer;
