import React from 'react';


const ExamInstru = () => {
    return (
        <section className='max-w-[1440px] mx-auto my-4 smm:my-10'>
            <div className='mx-4 smm:mx-10'>
                {/* course Structure */}
                <div className='bg-[#263448] p-3 smm:p-10'>
                    <h1 className='text-2xl text-[#01B6FF] font-semibold text-center uppercase mb-3'>Course Structure</h1>
                    <p className='text-[#01B6FF] text-lg font-semibold'>Time: 3 Hours</p>
                    <p className='text-[#01B6FF] text-lg font-semibold mt-2'>Total Marks: 124</p>
                    {/* table */}
                    <div className="rounded-md sm:p-4 text-[#9EB2D2] mt-5">
                        <div className="overflow-x-auto scrollbar-thin scrollbar-track-gray-600  scrollbar-thumb-sky-500 scrollbar-track-rounded-xl">
                            <table className="table-auto min-w-full text-xs">
                                <thead className="rounded-t-l">
                                    <tr className="text-lg text-center">
                                        <th title="Ranking" className="border border-[#9EB2D2] smm:p-3">Unit</th>
                                        <th title="Team name" className="border border-[#9EB2D2] smm:p-3">Title</th>
                                        <th title="Wins" className="border border-[#9EB2D2] smm:p-3">No. of questions</th>
                                        <th title="Losses" className="border border-[#9EB2D2] smm:p-3">Marks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="text-center text-base">
                                        <td className="border border-[#9EB2D2] px-3 py-2">
                                            <span>1</span>
                                        </td>
                                        <td className="border border-[#9EB2D2] px-3 py-2 text-left">
                                            <span>Java Principles</span>
                                        </td>
                                        <td className="border border-[#9EB2D2] px-3 py-2">
                                            <span>30</span>
                                        </td>
                                        <td className="border border-[#9EB2D2] px-3 py-2">
                                            <span>10</span>
                                        </td>
                                    </tr>
                                    <tr className="text-center text-base">
                                        <td className="border border-[#9EB2D2] px-3 py-2">
                                            <span>1</span>
                                        </td>
                                        <td className="border border-[#9EB2D2] px-3 py-2 text-left">
                                            <span>Java Principles</span>
                                        </td>
                                        <td className="border border-[#9EB2D2] px-3 py-2">
                                            <span>30</span>
                                        </td>
                                        <td className="border border-[#9EB2D2] px-3 py-2">
                                            <span>10</span>
                                        </td>
                                    </tr>
                                    <tr className="text-center text-base">
                                        <td className="border border-[#9EB2D2] px-3 py-2">
                                            <span>1</span>
                                        </td>
                                        <td className="border border-[#9EB2D2] px-3 py-2 text-left">
                                            <span>Java Principles</span>
                                        </td>
                                        <td className="border border-[#9EB2D2] px-3 py-2">
                                            <span>30</span>
                                        </td>
                                        <td className="border border-[#9EB2D2] px-3 py-2">
                                            <span>10</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='mt-10'>
                        <p className='text-[#01B6FF] text-lg font-semibold'>Unit 1 : Java Principles</p>
                        <p className='text-[#9EB2D2] mt-2'>There are about 22 chapters in the NCERT Biology Class 11 book which shed light on various facets of the field like Cell Cycle, Anatomy, Digestion, Mineral Nutrition, etc. Tabulated below are the units wise chapters: Chapter 1: The Living World.
                        </p>
                    </div>
                    <div className='mt-8'>
                        <p className='text-[#01B6FF] text-lg font-semibold'>Unit 2 : Java Principles</p>
                        <p className='text-[#9EB2D2] mt-2'>There are about 22 chapters in the NCERT Biology Class 11 book which shed light on various facets of the field like Cell Cycle, Anatomy, Digestion, Mineral Nutrition, etc. Tabulated below are the units wise chapters: Chapter 1: The Living World.
                        </p>
                    </div>
                    <div className='mt-8'>
                        <p className='text-[#01B6FF] text-lg font-semibold'>Unit 3 : Java Principles</p>
                        <p className='text-[#9EB2D2] mt-2'>There are about 22 chapters in the NCERT Biology Class 11 book which shed light on various facets of the field like Cell Cycle, Anatomy, Digestion, Mineral Nutrition, etc. Tabulated below are the units wise chapters: Chapter 1: The Living World.
                        </p>
                    </div>
                </div>
                {/* grading */}
                <div className='bg-[#263448] p-4 smm:p-10 mt-10'>
                    <h1 className='text-2xl text-[#01B6FF] font-semibold text-center uppercase mb-3'>Grading</h1>
                    {/* table */}
                    <div className="rounded-md sm:p-4 text-[#9EB2D2] mt-5">
                        <div className="overflow-x-auto scrollbar-thin scrollbar-track-gray-600  scrollbar-thumb-sky-500 scrollbar-track-rounded-xl">
                            <table className="table-auto min-w-full text-xs">
                                <thead className="rounded-t-l">
                                    <tr className="text-lg text-center">
                                        <th title="Ranking" className="border border-[#9EB2D2] p-3">Unit</th>
                                        <th title="Team name" className="border border-[#9EB2D2] p-3">Title</th>
                                        <th title="Wins" className="border border-[#9EB2D2] p-3">No. of questions</th>
                                        <th title="Losses" className="border border-[#9EB2D2] p-3">Marks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="text-center text-base">
                                        <td className="border border-[#9EB2D2] px-3 py-2">
                                            <span>1</span>
                                        </td>
                                        <td className="border border-[#9EB2D2] px-3 py-2 text-left">
                                            <span>Java Principles</span>
                                        </td>
                                        <td className="border border-[#9EB2D2] px-3 py-2">
                                            <span>30</span>
                                        </td>
                                        <td className="border border-[#9EB2D2] px-3 py-2">
                                            <span>10</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='flex justify-end gap-x-5'>
                    <button className=' w-full md:w-40 text-lg bg-[#08749e] text-white font-semibold rounded-md mt-10 px-4 py-1 hover:bg-[#1c627e] hover:text-[#d3d7d8] '>Start Exam</button>
                    <button className=' w-full md:w-40 text-lg bg-white text-[#08749e] font-semibold rounded-md mt-10 px-4 py-1 hover:border-[#d3d7d8] hover:text-[#08749e]'>Cancel</button>
                </div>
            </div>
        </section>
    );
};

export default ExamInstru;