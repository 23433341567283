import React from 'react';
import  Helmet  from "react-helmet";
const PrivacyPolicy = () => {
  return (
 
      
    <div >

<Helmet>
 
<title>Codeexampler Privacy Policy</title>
<meta name="description" content="Codeexampler Privacy Policy.CodeExampler provides Multiple Programming Examples for Beginners,CodeExampler provides Free Online Tutorials for beginners and  questions of all technology like java tutorial, python tutorial,c# tutorial for beginners in deeply knowledge " />
<link rel="canonical" href="https://www.codeexampler.com/privacy-policy" />
</Helmet>

    <div className="max-w-6xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold mb-6">CodeExampler Privacy Policy</h1>
      <p className="mb-6">At CodeExampler, we take your privacy very seriously. We are committed to protecting your personal information and providing a safe and secure environment for you to use our Free Local Business Listing Directory. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our Directory. By using our Directory, you consent to the terms and practices outlined in this Privacy Policy.</p>
  
      <h2 className="text-2xl font-bold mb-4">Information We Collect</h2>
      <p className="mb-6">When you use our Directory, we may collect personal information about you, such as your name, email address, phone number, and other contact details. We may also collect information about your business, such as the name, address, phone number, website, and other relevant details.</p>
  
      <p className="mb-6">We may also collect information about your use of the Directory, including the pages you visit, the listings you view, and the searches you perform. This information may be collected through the use of cookies and other tracking technologies.</p>
  
      <h2 className="text-2xl font-bold mb-4">How We Use Your Information</h2>
      <p className="mb-6">We use your personal information to provide you with the services you request, including the ability to create and manage your business listing on our Directory. We may also use your information to communicate with you about your listing, provide support, and respond to your inquiries.</p>
  
      <p className="mb-6">We may use the information we collect about your use of our Directory to improve the user experience, personalize your experience, and provide you with relevant information and recommendations.</p>
  
      <p className="mb-6">We may also use your information for research and analysis purposes, to improve and develop our services, and to comply with legal and regulatory requirements.</p>
  
      <h2 className="text-2xl font-bold mb-4">How We Protect Your Information</h2>
      <p className="mb-6">We take the security and protection of your personal information very seriously. We use a variety of technical and organizational measures to safeguard your information against unauthorized access, disclosure, and misuse.</p>
  
      <p className="mb-6">We use secure servers and encryption technology to protect your information, and we restrict access to your information to authorized personnel who need to use it to provide you with our services and <a class="text-blue-600" target="_blank" href="https://www.resumerpro.com/">ResumerPro is an excellent free resume builder Site create professional and creative resumes</a></p>
      
      <h2 className="text-2xl font-bold mb-4">Third-Party Disclosure</h2>
      <p className="mb-6">We do not share your personal information with third parties, except as necessary to provide you with our services, respond to your inquiries, or comply with legal and regulatory requirements.</p>
      <p className="mb-6">We may share your information with our service providers, such as website hosting, analytics, and customer support providers, who assist us in providing you with our services. We require these service providers to protect your information and only use it for the purposes we specify.</p>

      <p className="mb-6">We may also share your information in connection with a merger, acquisition, or sale of all or a portion of our assets, in which case your information will be subject to the acquiring company’s privacy policy.</p>




      <h2 className="text-2xl font-bold mb-4">Your Rights</h2>
      <p className="mb-6">You have the right to access, correct, update, or delete your personal information at any time. You can do this by contacting us using the contact information provided on our Directory.</p>
      <p className="mb-6">You may also have the right to object to or restrict the processing of your personal information, or to request that we provide your information to you in a portable format and <a class="text-blue-600" target="_blank" href="https://www.codeexampler.com/">CodeExampler Learn free Programming Tutorial For Beginners</a></p>




      <h2 className="text-2xl font-bold mb-4">Updates to this Policy</h2>
      <p className="mb-6">We may update this Privacy Policy from time to time. We will notify you of any material changes to this Policy by posting a notice on our Directory or by sending you an email or other notification. We encourage you to review this Policy periodically to stay informed about how we are protecting your personal information.</p>


      <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
      <p className="mb-6">If you have any questions or concerns about our Privacy Policy or our treatment of your personal information, please contact us using the contact information provided on our Directory.  At CodeExampler Free Local Business Listing Directory, we take your privacy seriously and strive to provide a safe and secure online environment for all our users. In this Privacy Policy, we will explain how we collect, use, and protect your personal information.</p>



      <h2 className="text-2xl font-bold mb-4">CCPA Privacy Rights (Do Not Sell My Personal Information):</h2>
      <p className="mb-6">If you are a California resident, the California Consumer Privacy Act (CCPA) grants you certain rights regarding your personal information. Under CCPA, you have the right to request that we disclose the categories of personal information that we collect about you, the categories of sources from which the personal information is collected, the business or commercial purpose for collecting or selling personal information, and the categories of third parties with whom we share personal information. You also have the right to request that we delete any personal information that we have collected from you. To exercise your rights under CCPA, please contact us using the information provided at the end of this Privacy Policy.</p>






      <h2 className="text-2xl font-bold mb-4">GDPR Data Protection Rights:</h2>
      <p className="mb-6">If you are a resident of the European Economic Area (EEA), you have certain data protection rights under the General Data Protection Regulation (GDPR). These rights include the right to access, rectify, or erase your personal data, the right to object to the processing of your personal data, the right to restrict the processing of your personal data, and the right to data portability. To exercise your rights under GDPR, please contact us using the information provided at the end of this Privacy Policy.</p>



      <h2 className="text-2xl font-bold mb-4">Children's Information:</h2>
      <p className="mb-6">CodeExampler Free Local Business Listing Directory is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under the age of 18. If we become aware that we have collected personal information from a child under the age of 18, we will take steps to delete the information as soon as possible.</p>



      <h2 className="text-2xl font-bold mb-4">Log Files:</h2>
      <p className="mb-6">Like many other websites, we collect and use the data contained in log files. The information in the log files may include your IP address, browser type, Internet Service Provider (ISP), date/time stamp, referring/exit pages, and possibly the number of clicks. This information is used to analyze trends, administer the site, track user's movement around the site, and gather demographic information. IP addresses and other such information are not linked to any information that is personally identifiable.</p>
      <p className="mb-6">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
      <p className="mb-6">If you have any questions about this Privacy Policy, please contact us at contact@CodeExampler.com.  </p>

</div></div>

  );
};

export default PrivacyPolicy;
