import React from "react";
import  Helmet  from "react-helmet";
function TermsAndConditions() {
  return (
    <div className="p-6">


<Helmet>
 
<title>Codeexampler Terms-and-conditions</title>
<meta name="description" content="Codeexampler Terms_and_conditions.CodeExampler provides Multiple Programming Examples for Beginners,CodeExampler provides Free Online Tutorials for beginners and  questions of all technology like java tutorial, python tutorial,c# tutorial for beginners in deeply knowledge " />
<link rel="canonical" href="https://www.codeexampler.com/terms-and-conditions" />
</Helmet>

    <h1 className="text-3xl font-bold mb-4">Welcome to CodeExampler Free Local Business Listing Directory</h1>
    <p className="mb-4">
      If you continue to use the Directory, you agree to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern CodeExampler’ relationship with you in relation to this Directory. If you do not agree to these terms and conditions, please do not use the Directory.
    </p>
    <p className="mb-4">
      The use of the Directory is subject to the following terms of use:
    </p>
    <ul className="list-disc list-inside mb-4">
      <li>The content of the pages of this Directory is for your general information and use only. It is subject to change without notice.</li>
      <li>The Directory contains material that is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
      <li>All trademarks reproduced in this Directory, which are not the property of, or licensed to the operator, are acknowledged on the Directory.</li>
      <li>Unauthorized use of this Directory may give rise to a claim for damages and/or be a criminal offence.</li>
      <li>From time to time, this Directory may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
      <li>Your use of this Directory and any dispute arising out of such use of the Directory is subject to the laws of the United States of America.</li>
      <li>By using the Directory, you agree that CodeExampler and its affiliates, officers, employees, agents, partners, and licensors shall not be liable to you for any direct, indirect, incidental, special, consequential, or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, resulting from your use of the Directory.</li>
      <li>CodeExampler reserves the right to modify or terminate the Directory at any time without prior notice. We may also modify these terms and conditions at any time, and your continued use of the Directory following any changes shall be deemed to be your acceptance of such changes.</li>
      <li>The views and opinions expressed by users of the Directory do not necessarily reflect the opinions or views of CodeExampler, its employees, or affiliates. We reserve the right to remove any listing or content that we deem inappropriate or in violation of our policies.</li>
      <li>You are solely responsible for the information and content you provide in your listing on the Directory. You represent and warrant that you have the right to submit the content and that the content is accurate, complete, and not misleading in any way.</li>
      <li>You agree to indemnify and hold harmless CodeExampler and its affiliates, officers, employees, agents, partners, and licensors from any claims, losses, damages, liabilities, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of the Directory or your breach of these terms and conditions.</li>
    </ul>
   </div>
  );
};

export default TermsAndConditions;
